/* ************************
07.2: Form Wizard
********************* */

#smartwizard {
    @extend .border-0;
    .nav {
        @extend .border-0;
        li {
            a {
                font-size: 17px;
                @extend .bold;
                height: 80px;
                display: flex;
                align-items: center;
                @include customMq(0, 640) {
                    justify-content: center;
                }
            }
            &:last-child {
                flex-grow: 0;
                @include customMq(0, 640) {
                    margin-top: 10px;
                }

                a {
                    margin-left: 20px;
                    margin-right: 20px;
                    @extend .radius;
                    width: 80px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    border: 1px solid #DBF7E8;
                    background: #fff;
                    &:before, &:after {
                        display: none;
                    }
                    i {
                        font-size: 50px;
                    }
                }
            }
        }
    }

    .card-body {
        padding-bottom: 0;
        @include mobileLg {
           padding: 0;
           padding-top: 20px;
        }
    }
}

.step-done {
    padding: 100px 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .btn-circle {
        margin-bottom: 25px;
    }
    p {
        max-width: 510px;
    }
    > p {
        margin: 0 auto;
        text-align: center;
    }
}

.sw .toolbar {
    button {
        &.disabled {
            display: none;
        }
    }
    @include mobileMd {
        padding: 0 15px 30px;
    }
}

.smartwizard2-top-title {
    margin-top: 27px;
    margin-bottom: 24px;
    padding: 0 30px;
}
#smartwizard2 {
    padding: 0 30px;
    @include mobileMd {
        padding: 0 15px;
    }

    .toolbar {
        padding-top: 10px;
        button.btn {
            padding: 13px 26px;
        }
    }

    .nav {
        margin-bottom: 30px;
        li {
            &:last-child {
                flex-grow: 0;
                overflow: hidden;
            }
        }
    }
}

#example-vertical {
    > .steps {
        width: 240px;
        margin-right: 30px;
        float: left;
        @include mobileLg {
            width: 160px;
        }
        @include mobileMd {
            width: 100%;
            float: none;
        }
        .current-info {
            position: absolute;
            left: -999em;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            @include mobileMd {
                margin: 0 15px;
                margin-bottom: 30px;
            }

            li {
                padding: 40px 30px;
                display: flex;
                align-items: center;
                position: relative;
                @include mobileMd {
                    justify-content: center;
                    padding: 20px 10px;
                }
                &:last-child {
                    display: none;
                }
                &:after {
                    height: 100%;
                    width: 5px;
                    background-color: #f5f5f5;
                    right: 0;
                    top: 0;
                    position: absolute;
                    content: "";
                    @include mobileMd {
                        height: 5px;
                        width: 100%;
                        left: 0;
                        top: auto;
                        bottom: 0;
                    }
                }
                &.done, &.current {
                    &:after {
                        background-color: #67CF94;
                    }
                    a {
                        @extend .black;
                        i {
                            color: #8280FD;
                        }
                    }
                }
                &.current {
                    &:after {
                        background-color: #8ef2b9;
                    }
                }
                &.error {
                    &:after {
                        background-color: #FC7383;
                    }
                }
                a {
                    .number {
                        position: absolute;
                        left: -999em;
                    }
                    font-size: 17px;
                    @extend .c4;
                    @extend .bold;
                    @include mobileMd {
                        text-align: center;
                    }

                    i {
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    > .content {
        float: left;
        width: calc(100% - 270px);
        @include mobileLg {
            width: calc(100% - 190px);
        }
        @include mobileMd {
            width: 100%;
            float: none;
        }
        > h3 {
            position: absolute;
            left: -999em;
        }
    }

    > .actions {
        margin-left: 296px;
        padding-bottom: 30px;
        @include mobileLg {
            margin-left: 210px;
        }
        @include mobileMd {
            margin-top: 10px;
            margin-left: 15px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                &.disabled {
                    display: none;
                }
                &:not(:last-child) {
                    margin-right: 15px;
                }
                a {
                    &[href="#next"], &[href="#finish"] {
                        @extend .btn;
                        padding: 13px 26px;
                    }
                    &[href="#previous"] {
                        @extend .bold;
                        color: #CAC6FB;
                        padding: 10px 0;
                    }
                    display: inline-block;
                }
            }
        }
    }

    .step-done {
        padding: 70px 15px 100px;
        align-items: initial;

        > div {
            padding-left: 150px;
            @include medium {
                padding-left: 0px;
            }
        }
    }
    .card-body {
        @include mobileMd {
            padding: 0 15px;
        }
    }
}

.vertical-step-wizard {
    min-height: 510px;
    overflow: hidden;
}